// General block
import { initGeneralBlock } from "./generalblock.js";
initGeneralBlock();

// Edge To Edge block
require("./edgetoedgeblock.js");

// Parallax block
if (navigator.userAgent.match(/Trident\/7\./)) { // if IE
  require("./parallaxblock-ie.js");
} else if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad') {
  require("./parallaxblock.js");
}

// Call to action
require("./calltoaction.js");

// Product model block
require("./productmodelblock.js");