$(document).ready(function () {

  var parallaxArray = document.getElementsByClassName("parallaxblockmodel");
    // Fix for "jumpy" background on IE11
    if (parallaxArray.length > 0) {

        $('body').on("mousewheel",
          function() {
            // remove default behavior
            event.preventDefault();

            //scroll without smoothing
            var wheelDelta = event.wheelDelta;
            var currentScrollPosition = window.pageYOffset;
            window.scrollTo(0, currentScrollPosition - wheelDelta + 5);
          });

        $('body').keydown(function(e) {
          e.preventDefault(); // prevent the default action (scroll / move caret)
          var currentScrollPosition = window.pageYOffset;

          switch (e.which) {
          case 38: // up
            window.scrollTo(0, currentScrollPosition - 120);
            break;
          case 40: // down
            window.scrollTo(0, currentScrollPosition + 120);
            break;
          default:
            return; // exit this handler for other keys
          }
        });
    } 
});