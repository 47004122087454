let productModel = document.querySelector('.product-model');
let productModelWrapper = document.querySelector(".product-model-wrapper");
let loadSpinner = null;
const preLoadImage = document.querySelector(".startImage");
let productImages = document.getElementsByClassName("product-images");
let noStart360 = null;
if (productImages.length > 0) {
    startImageURL = JSON.parse(productImages[0].innerHTML).images[0];
}

let start360Image = document.querySelector(".Image360");;

document.addEventListener("DOMContentLoaded", function () {
    if (productModel != null) {
        noStart360 = productModel.dataset.nostart360 === 'True';
        loadSpinner = document.querySelector('.productSpinner');
    }
    

    if (noStart360) {
        start360Image.addEventListener(('click'), start360);
        preLoadImage.src = startImageURL;
        
    } else {
        start360();
    }
});

function start360() {
    if (loadSpinner != null) {
        loadSpinner.classList.remove('productSpinner--hidden');
        preLoadImage.classList.add('hide');
        start360Image.classList.add('hide');
    }
    
    
    var stages = [];
    var productImageLists = [];
    var productSpinners = document.getElementsByClassName("productSpinner");

    for (var i = 0; i < productImages.length; i++) {
        var imagesAsJson = JSON.parse(productImages[i].innerHTML);
        var isMobile = productImages[i].previousElementSibling;
        if (getComputedStyle(isMobile).display === "block") {
            productImageLists.push(imagesAsJson.mobileImages === undefined ? imagesAsJson.images : imagesAsJson.mobileImages);
        } else {
            productImageLists.push(imagesAsJson.images);
        }
        var canvas = productImages[i].nextElementSibling;
        stages.push(new createjs.Stage(canvas));
    }

    for (var j = 0; j < stages.length; j++) {
        var stage = stages[j];
        var spinner = productSpinners[j];
        stage.enableMouseOver();
        stage.mouseMoveOutside = true;
        createjs.Touch.enable(stage, false, true);
        stage.preventSelection = false;

        var bg = new createjs.Shape();
        stage.addChild(bg);

        var bmp = new createjs.Bitmap();
        stage.addChild(bmp);

        createjs.Ticker.addEventListener("tick", stage);

        let stageParams = {
            stage: stage,
            bmp: bmp,
            images: [],
            spinner: spinner,
            currentFrame: 0,
            totalFrames: productImageLists[j].length,
            startX: undefined
        }

        var queue = new createjs.LoadQueue();
        queue.setMaxConnections(36);
        queue.addEventListener("complete", (event) => {
            handleComplete(event, stageParams);
        }, this);

        queue.addEventListener("fileload", (event) => {
            handleFileLoad(event, stageParams);
        }, this);

        queue.loadManifest(productImageLists[j]);
    }

    createjs.Ticker._setFPS(60);
    createjs.Ticker.useRAF = true;

}

function handleFileLoad(event, stageParams) {
    //ful fix för IE där event.result.height ibland är 0 och ibland korrekt höjd.
    if (stageParams.stage.canvas.height === 150 || stageParams.stage.canvas.height === undefined) {
        stageParams.stage.canvas.height = event.result.height;
        stageParams.stage.canvas.width = event.result.width;    
    }
}

function handleComplete(event, stageParams) {
    stageParams.images = event.target._loadQueueBackup;
    if (stageParams.stage.canvas.height !== event.target._loadQueueBackup[0]._result.height) {
        stageParams.stage.canvas.height = event.target._loadQueueBackup[0]._result.height;        
        stageParams.stage.canvas.width = event.target._loadQueueBackup[0]._result.width;
    }

    addNavigation(stageParams);
    update360(0, stageParams);
}

function update360(dir, stageParams) {
    stageParams.currentFrame += dir;
    if (stageParams.currentFrame < 0) stageParams.currentFrame = stageParams.totalFrames - 1;
    else if (stageParams.currentFrame > stageParams.totalFrames - 1) stageParams.currentFrame = 0;
    stageParams.bmp.image = stageParams.images[stageParams.currentFrame]._result;
    stageParams.spinner.style.display = "none";
}

function addNavigation(stageParams) {
    stageParams.stage.on("mouseover", mouseOver);
    stageParams.stage.on("mousedown", (event) => {
        mousePressed(event, stageParams);
    });
    stageParams.stage.on("pressmove", (event) => {
        mouseMoved(event, stageParams);
    });
    stageParams.stage.on("pressup", mouseUp);
    stageParams.stage.on("mouseleave", mouseLeave);
    document.body.style.cursor = 'auto';
}

function mouseOver(event) {
    document.body.style.cursor = 'pointer';
}

function mousePressed(event, stageParams) {
    stageParams.startX = event.rawX;
    document.body.style.cursor = 'w-resize';
}

function mouseMoved(event, stageParams) {
    var dx = event.rawX - stageParams.startX;
    var abs_dx = Math.abs(dx);
    if (abs_dx > 5) {
        update360(dx / abs_dx, stageParams);
        stageParams.startX = event.rawX;
    }
}

function mouseLeave(event) {
    document.body.style.cursor = 'auto';
}

function mouseUp(event) {
    document.body.style.cursor = 'pointer';
}