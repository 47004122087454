var _ = require("underscore");

function initGeneralBlock() {
    $(document).ready(function () {

        adjustHeight();

        $(".display-more").click(function () {
            $(this).siblings().css("max-height", "none").removeClass("truncated");
            $(this).parent().css("max-height", "none");
        });

        $(window).on('orientationchange hresize', adjustHeight);

        // Horizontal and vertical window resize events.
        var prev_width = $(window).width();
        $(window).on('resize', _.throttle(function () {
            var width = $(window).width();
            if (width !== prev_width) {
                $(window).trigger('hresize');
            }
            prev_width = width;
        }, 200));

    });
}

function adjustHeight() {
    if (window.innerWidth < 767) {
        setTimeout(function () {
            $('.generalblockmodel .trunc-container').each(function () {
                var childrenHeight = 0;
                $(this).children().each(function () {
                    childrenHeight = childrenHeight + $(this).outerHeight(true);
                });
                if (childrenHeight < parseInt($(this).parent().css("max-height"))) {
                    $(this).css("max-height", "none");
                    $(this).removeClass("truncated");
                } else {
                    $(this).addClass("truncated");
                    if (this.style.removeProperty) {
                        this.style.removeProperty("max-height");
                    } else {
                        this.style.removeAttribute("max-height");
                    }
                }
            });
        }, 100);
    }
}

export {initGeneralBlock};