$(window).on("load", function(){
    setTimeout(function () {
        $("#slide-in-toggle-1 span").removeClass("hidden");
		var slideWidth = Math.ceil($("#slide-in-toggle-1").outerWidth());
		if (slideWidth < 150) {
			slideWidth = 150;
        }
		$("#toggle-shape").height(slideWidth);
		$("#slide-in-toggle-1").width(slideWidth);
    }, 90);
});

$(document).ready(function () {
    
    $("#toggle-shape, #slide-in-toggle-1").hover(
        function() {
            $("#toggle-shape").css("backgroundColor", "#d7b505");
        },
        function() {
            $("#toggle-shape").css("backgroundColor", "#efc906");
        }
    );

    $("#toggle-shape, #slide-in-toggle-1, .close-btn").click(function () {
        var hidden1 = $('.hidden-slide');
        if (hidden1.hasClass('visible')) {
            hidden1.removeClass('visible');
        } else {
            hidden1.addClass('visible');
        }
    });
});